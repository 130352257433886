<template>
  <div>
    <route-buttons />
    <b-row class="d-grid match-height">
      <b-col>
        <bma-card
          :title="businessModelAnalysis[5].title"
          :color="businessModelAnalysis[5].color"
          :score="businessModelAnalysis[5].score"
        />
      </b-col>
      <b-col>
        <div>
          <bma-card
            :title="businessModelAnalysis[6].title"
            :color="businessModelAnalysis[6].color"
            :score="businessModelAnalysis[6].score"
          />
        </div>
        <div>
          <bma-card
            :title="businessModelAnalysis[7].title"
            :color="businessModelAnalysis[7].color"
            :score="businessModelAnalysis[7].score"
          />
        </div>
      </b-col>
      <b-col>
        <bma-card
          :title="businessModelAnalysis[0].title"
          :color="businessModelAnalysis[0].color"
          :score="businessModelAnalysis[0].score"
        />
      </b-col>
      <b-col>
        <div>
          <bma-card
            :title="businessModelAnalysis[2].title"
            :color="businessModelAnalysis[2].color"
            :score="businessModelAnalysis[2].score"
          />
        </div>
        <div>
          <bma-card
            :title="businessModelAnalysis[3].title"
            :color="businessModelAnalysis[3].color"
            :score="businessModelAnalysis[3].score"
          />
        </div>
      </b-col>
      <b-col>
        <bma-card
          :title="businessModelAnalysis[1].title"
          :color="businessModelAnalysis[1].color"
          :score="businessModelAnalysis[1].score"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <bma-card
          :title="businessModelAnalysis[8].title"
          :color="businessModelAnalysis[8].color"
          :score="businessModelAnalysis[8].score"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="text-center"
      >
        <bma-card
          :title="businessModelAnalysis[4].title"
          :color="businessModelAnalysis[4].color"
          :score="businessModelAnalysis[4].score"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import routeButtons from '@/views/App/Reports/components/Route_buttons.vue'
import BmaCard from '@/views/App/Reports/components/BmaCard.vue'

export default {
  name: 'BusinessModelAnalysis',
  components: {
    BmaCard,
    BRow,
    BCol,
    routeButtons,
  },
  computed: {
    businessModelAnalysis() {
      return this.$store.getters['businessModelAnalysisReport/getBusinessModelAnalysis']
    },
  },
  created() {
    this.getBusinessModelAnalysis()
  },
  methods: {
    getBusinessModelAnalysis() {
      this.$store.dispatch('businessModelAnalysisReport/businessModelAnalysis', this.$route.params.id)
    },
  },
}
</script>
<style scoped>
.d-grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

@media (max-width: 992px) {
  .d-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

</style>
